import { useState, useEffect} from "react"
import Image from "next/legacy/image";

export default function StoneleighHeader(headerData) {
    const [mobileMenuClass, setMobileMenuClass] = useState('');
    const [iconClass, setIconClass] = useState('');
    const [searchClass, setSearchClass] = useState('');
    const [subMenuClass, setSubMenuClass] = useState('');
    const [pathName, setPathName] = useState('');

    useEffect(() => {
        setPathName(window.location.pathname);

        handleLoad();

        window.addEventListener('scroll', isSticky);
        window.addEventListener('load', handleLoad);
        window.addEventListener('resize', handleLoad);

        return () => {
            window.removeEventListener('scroll', isSticky);
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('resize', handleLoad);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('#stoneleigh-header-container');
        
        const el = document.querySelector('#header-container');
        const style = window.getComputedStyle(el);
        const headerHeight = ['height']
        .map((key) => parseInt(style.getPropertyValue(key), 10))
        .reduce((prev, cur) => prev + cur);

        const scrollTop = window.scrollY;
        scrollTop >= headerHeight && window.screen.width > 1200 ? addSticky() : removeSticky();
    };

    const handleLoad = (e) => {
        setTimeout( () => {
            let el = document.querySelector('#header-wrapper');
            let style = window.getComputedStyle(el);
            let originalHeaderHeight = ['height']
            .map((key) => parseInt(style.getPropertyValue(key), 10))
            .reduce((prev, cur) => prev + cur);
    
            const plantHeaderHeight = originalHeaderHeight + 80;
            const plantHeader = document.querySelector('#plants-header-container');
    
            if(plantHeader){
                if(window.screen.width > 1200){
                    plantHeader.style.paddingTop = plantHeaderHeight + 'px';
                } else {
                    plantHeader.style.paddingTop = '';
                }
            }
    
            const singlePlantHeaderHeight = originalHeaderHeight + 80;
            const singlePlantHeader = document.querySelector('#single-plants-header-container');
    
            if(singlePlantHeader){
                if(window.screen.width > 1200){
                    singlePlantHeader.style.paddingTop = singlePlantHeaderHeight + 'px';
                } else {
                    singlePlantHeader.style.paddingTop = '';
                }
            }
    
            const pageBuilderHeight = originalHeaderHeight;
            const pageBuilderHeader = document.querySelector('#stoneleigh-page-builder-container');
    
            if(pageBuilderHeader){
                if(window.screen.width > 1200){
                    pageBuilderHeader.style.paddingTop = pageBuilderHeight + 'px';
                } else {
                    pageBuilderHeader.style.paddingTop = '';
                }
            }
    
            const homeHeaderHeight = originalHeaderHeight;
            const homeHeader = document.querySelector('#stoneleigh-hero-slider');
    
            if(homeHeader){
                if(window.screen.width > 1200){
                    homeHeader.style.marginTop = homeHeaderHeight + 'px';
                } else {
                    homeHeader.style.marginTop = '';
                }
            }
        }, 250)
    }

    const addSticky = () => {
        const header = document.querySelector('#stoneleigh-header-container');
        header.classList.add('is-sticky');
    }

    const removeSticky = () => {
        const header = document.querySelector('#stoneleigh-header-container');
        header.classList.remove('is-sticky');
    }

    const toggleMobileMenu = (e) => {
        e.preventDefault();
        
        if(mobileMenuClass === ''){
            setMobileMenuClass('is-visible');
            setIconClass('active');
        } else {
            setMobileMenuClass('');
            setIconClass('');
        }
    };

    const toggleSearch = (e) => {
        e.preventDefault();
        
        if(searchClass === ''){
            setSearchClass('is-visible');
        } else {
            setSearchClass('');
        }
    };

    const toggleMobileMenuSubMenu = (e, index) => {
        e.preventDefault();
        
        if(subMenuClass === index){
            setSubMenuClass('');
        } else {
            setSubMenuClass(index);
        }
    };
    
    let mainMenu = headerData?.header?.data?.mainMenu?.menuItems?.nodes;
    let stoneleighMenu = headerData?.header?.data?.stoneleighMenu?.menuItems?.nodes;
    let stoneleighButtonsMenu = headerData?.header?.data?.stoneleighButtonsMenu?.menuItems?.nodes;

    const flatListToHierarchical = (
        data = [],
        {idKey='key',parentKey='parentId',childrenKey='children'} = {}
    ) => {
        const tree = [];
        const childrenOf = {};
        data.forEach((item) => {
            const newItem = {...item};
            const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
            childrenOf[id] = childrenOf[id] || [];
            newItem[childrenKey] = childrenOf[id];
            parentId
                ? (
                    childrenOf[parentId] = childrenOf[parentId] || []
                ).push(newItem)
                : tree.push(newItem);
        });
        return tree;
    };

    const groupMenuChildren = (
        data = []
    ) => {
        data.forEach( (item) => {
            if(item?.children?.length){
                let chunks = [];
                const chunkSize = Math.ceil(item.children.length/2);

                for (let i = 0; i < item.children.length; i +=  chunkSize){
                    const chunk = item.children.slice(i, i + chunkSize);
                    chunks.push(chunk);
                }

                item.children = chunks;
            }
        })
    
        return data;
    }

    const mm = flatListToHierarchical(mainMenu);
    const mmFinal = groupMenuChildren(mm);

    mainMenu = mmFinal;

    return (
    <>
    <div id="header-wrapper">
    <div id="header-container" className="container-fluid">
        <div className="container xlarge-container">
            <div>
                <div>
                    <a href="/">
                        <img width={144} height={52} src="/assets/images/logo.svg" alt="Natural Lands" />
                    </a>
                </div>
                <div>
                    <div>
                    {
                        mainMenu && mainMenu.length > 0 && (
                                <ul>
                                    {
                                        mainMenu.map((item, index) => {
                                            let classes = '';
                                            
                                            if(item.children.length > 0){
                                                classes = 'menu-item menu-item-has-children';
                                            } else {
                                                classes = 'menu-item';
                                            }

                                            if(item.cssClasses){
                                                item.cssClasses.forEach( (item) => {
                                                    classes += ' ' + item;
                                                });
                                            }

                                            return (
                                                <li key={index} className={`${classes}`}>
                                                    {
                                                        item.path && item.title !== 'Blog' && (
                                                            <a target={item.target ? "_blank" : "_self"} href={`${item.menuItem.useAbsolutePath === true ? item.url : item.path}`}>
                                                                {item.title}
                                                            </a>
                                                        )
                                                    }
                                                    {
                                                        !item.path && (
                                                            <span>{item.title}</span>
                                                        )
                                                    }
                                                    {
                                                        item.children.length > 0 && (
                                                            <div className="mega-menu">
                                                                <div>
                                                                    <div>
                                                                        {
                                                                            item.children.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <ul>
                                                                                            {
                                                                                                item.map((innerItem, innerIndex) => {
                                                                                                    return (
                                                                                                        <li key={innerIndex}>
                                                                                                            <span className="title">
                                                                                                                {innerItem.title}
                                                                                                            </span>
                                                                                                            {
                                                                                                                innerItem.children.length > 0 && (
                                                                                                                    <ul className="grandchildren">
                                                                                                                        {
                                                                                                                            innerItem.children.map((childItem) =>{
                                                                                                                                return (
                                                                                                                                    <li>
                                                                                                                                        <a dangerouslySetInnerHTML={{__html: childItem.title}} target={childItem.target ? "_blank" : "_self"} href={`${childItem.menuItem.useAbsolutePath === true ? childItem.url : childItem.path}`}></a>
                                                                                                                                    </li>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </ul>
                                                                                                                )
                                                                                                            }
                                                                                                        </li>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        {
                                                                            item.menuItem.content && (
                                                                                <a href={`${item.menuItem.link}`}>
                                                                                    <div className="image-wrapper">
                                                                                        <Image
                                                                                            src={`${item.menuItem.image.sourceUrl}`}
                                                                                            layout='fill'
                                                                                        />
                                                                                    </div>
                                                                                    <div dangerouslySetInnerHTML={{__html: item.menuItem.content}}></div>
                                                                                </a>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        <a aria-label="Mobile Search Link" id="mobile-search-toggle" href="/search/">
            <span className="icon-search"></span>
        </a>
        <a aria-label="Mobile Menu Link" id="mobile-menu-icon" href="#" className={`${iconClass}`}
            onClick={(e) => {
                toggleMobileMenu(e);
            }}
        >
            <span className="icon-menu"></span>
            <span className="icon-close"></span>
        </a>
    </div>
    <div id="stoneleigh-header-container" className="container-fluid">
        <div className="container xlarge-container">
            <div>
                <div>
                    <a href="/">
                        <img width={144} height={52} src="/assets/images/footer-logo.svg" alt="Natural Lands" />
                    </a>
                </div>
                <div>
                    <h1 className="visually-hidden">Stoneleigh: <em>a natural garden</em></h1>
                    <p className="title">Stoneleigh: <em>a natural garden</em></p>
                    {
                        stoneleighMenu && stoneleighMenu.length > 0 && (
                            <ul>
                                {
                                    stoneleighMenu.map((item, index) => {
                                        let classes = '';

                                        if(item.cssClasses){
                                            item.cssClasses.forEach( (item) => {
                                                classes += ' ' + item;
                                            });
                                        }

                                       try{
                                            let path = '';

                                            if(item.menuItem.useAbsolutePath){
                                                path = new URL(item.url);

                                                if(pathName === path.pathname){
                                                    classes += ' current_page_item';
                                                }
                                            } else {
                                                if(pathName === item.path){
                                                    classes += ' current_page_item';
                                                }
                                            }
                                        } catch(error){
                                            console.log(error);
                                            console.log(item.url);
                                        }

                                        return (
                                            <li key={index} className={`${classes}`}>
                                                {
                                                    item.path && item.title !== 'Blog' && (
                                                        <a target={item.target ? "_blank" : "_self"} href={`${item.menuItem.useAbsolutePath ? item.url : item.path}`}>
                                                            {item.title}
                                                        </a>
                                                    )
                                                }
                                                {
                                                    !item.path && (
                                                        <span>{item.title}</span>
                                                    )
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        )
                    }
                </div>
                <div>
                    {
                        stoneleighButtonsMenu && stoneleighButtonsMenu.length > 0 && (
                            <ul>
                                {
                                    stoneleighButtonsMenu.map((item, index) => {
                                        let classes = '';
                                        
                                        if(item.children && item.children?.length > 0){
                                            classes = 'menu-item menu-item-has-children';
                                        } else {
                                            classes = 'menu-item';
                                        }

                                        if(item.cssClasses){
                                            item.cssClasses.forEach( (item) => {
                                                classes += ' ' + item;
                                            });
                                        }

                                        return (
                                            <li key={index} className={`${classes}`}>
                                                {
                                                    item.path && item.title !== 'Blog' && (
                                                        <a target={item.target ? "_blank" : "_self"} href={`${item.path}`}>
                                                            {item.title}
                                                        </a>
                                                    )
                                                }
                                                {
                                                    !item.path && (
                                                        <span>{item.title}</span>
                                                    )
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        )
                    }
                </div>
            </div>
        </div>
    </div>
    </div>
    <div id="mobile-menu" className={`${mobileMenuClass}`}>
        <div id="mobile-menu-main">
            <ul>
                {
                    mainMenu && mainMenu.length >0 && mainMenu.map((item, index) => {
                        let classes = '';
                                            
                        if(item.children.length > 0){
                            classes = 'menu-item menu-item-has-children';
                        } else {
                            classes = 'menu-item';
                        }

                        if(item.cssClasses){
                            item.cssClasses.forEach( (item) => {
                                classes += ' ' + item;
                            });
                        }

                        return (
                            <li key={`mobile-menu-item-${index}`} className={`${classes}`}>
                               {
                                    item.children?.length > 0 && (
                                        <a href="#" className={`${subMenuClass === index ? 'active' : '' }`} onClick={(e) => {
                                            toggleMobileMenuSubMenu(e, index);
                                          }}>
                                            {item.title}
                                        </a>
                                    )
                               }
                               {
                                    item.children.length === 0 && (
                                        <a target={item.target ? "_blank" : "_self"} href={`${item.menuItem.useAbsolutePath === true ? item.url : item.path}`}>
                                            {item.title}
                                        </a>
                                    )
                               }
                                {
                                    item.children.length > 0 && (
                                        <div className={`sub-menu ${subMenuClass === index ? 'active' : '' }`}>
                                            <ul>
                                                {
                                                    item.children.map((item) => {
                                                        return (
                                                            item.map((innerItem, innerIndex) => {
                                                                return (
                                                                    <li key={innerIndex}>
                                                                        <span className="title">
                                                                            {innerItem.title}
                                                                        </span>
                                                                        {
                                                                            innerItem.children.length > 0 && (
                                                                                <ul className="grandchildren">
                                                                                    {
                                                                                        innerItem.children.map((childItem) =>{
                                                                                            return (
                                                                                                <li>
                                                                                                    <a dangerouslySetInnerHTML={{__html: childItem.title}} target={childItem.target ? "_blank" : "_self"} href={`${childItem.menuItem.useAbsolutePath === true ? childItem.url : childItem.path}`}></a>
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            )
                                                                        }
                                                                    </li>
                                                                )
                                                            })
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    )
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    </div>
    </>
  );
}